<script lang="jsx">
export default {

  name: 'inviter-people-show',

  functional: true,

  render: (h, {props, data})  => {
    const { data: _propsTags } = props
    return (
      <div class="inviter-people-show">
        {
          _propsTags.map((item, index) => {
            return (
              <el-tag key={item.name} type="info" effect="plain">
                {item.name}
              </el-tag>
            )
          })
        }
      </div>
    )
  },
}
</script>
<style lang="scss">
.inviter-people-show {
  .el-tag {
    margin-right: 10px;
    margin-bottom: 10px;
    background: $white;
    border-radius: 17px;
    padding: 0 17px;
    border: 1px solid $grey600;
    font-size: 12px;
    font-weight: 400;
    color: $lightblack;
  }
}
</style>
