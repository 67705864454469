<template>
  <div v-if="currentState" class="satisfaction-inviter-view">
    <!-- <tojoy-title-content title="邀请评价人" /> -->
    <section class="satisfaction-inviter-view__section">
      <small-user-card
        :size="64"
        :data="{
          avatar: inviterPerson.avatar,
          username: inviterPerson.userName,
          position: inviterPerson.job,
          organization: inviterPerson.department,
          userid: inviterPerson.userId
        }"
        font="24"
        border
        @user-card="u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })"
      />
      <component :is="'InviterTitleComp'" :schema="currentState" />
      <div class="inviter-form">
        <component
          ref="inviterRef"
          :is="assemblyData[currentState].comp"
          :data="assemblyData[currentState].data"
          :payload="assemblyData[currentState].payload"
          :disabled="assemblyData[currentState].attrs.disabled"
        ></component>
      </div>
    </section>
    <div class="inviter-footer">
      <inviter-buttons
        v-show="currentState === 'auditStart'"
        btn-text="取消"
        @click="$router.push({ name: 'satisfaction-mine' })"
      />
      <component
        v-bind="assemblyData[currentState].buttonComp.attrs"
        :is="assemblyData[currentState].buttonComp.comp"
        :btn-text="assemblyData[currentState].buttonComp.text"
        @click="assemblyData[currentState].buttonComp.handler"
      />
    </div>
  </div>
</template>

<script>
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import SmallUserCard from '@/components/democracy/small-user-card.vue'
import InviterPeopleShow from '@/components/democracy/inviter-people-show.vue'
import InviterPeopleOpera from '@/components/democracy/inviter-people-opera.vue'
import InviterButtons from '@/components/democracy/inviter-buttons.jsx'
import { InviterTitleComp } from '@/components/democracy/state.js'
import { mineInviteQuery, mineInviteApprove } from '@/api/api_satisfaction'
const mockShowData = () => {
  let data = []

  for (let index = 0; index < 100; index++) {
    data.push({
      id: 2016 + index,
      name: `韩梅梅${index}`
    })
  }

  return data
}

const Button_Back = {
  comp: InviterButtons,
  text: '返回',
  attrs: { plain: true }
}

const Button_Audit = {
  comp: InviterButtons,
  text: '提交审批',
  attrs: { type: 'primary' }
}

export default {
  name: 'democracy-inviter-view',
  components: {
    TojoyTitleContent,
    SmallUserCard,
    InviterTitleComp,
    InviterButtons
  },
  data() {
    return {
      inviterPerson: {},
      inviterData: mockShowData(),
      inviterFormData: {
        inviterPeople: [],
        approvePeople: {}
      },
      inviterNums: {
        maxNum: 0,
        minNum: 0
      },
      currentState: '' // 'auditStart' // auditPass | automatic | auditing | auditReject | auditStart | auditAutoReject
    }
  },
  computed: {
    assemblyData() {
      return {
        automatic: {
          comp: InviterPeopleShow,
          data: this.inviterData,
          buttonComp: {
            ...Button_Back,
            handler: () => {} // TODO
          },
          attrs: {}
        },
        assign: {
          comp: InviterPeopleShow,
          data: this.inviterData,
          buttonComp: {
            ...Button_Back,
            handler: () => {} // TODO
          },
          attrs: {}
        },
        auditStart: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Audit,
            handler: this.handleSubmit // TODO
          },
          attrs: { disabled: false }
        },
        auditing: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Back,
            handler: () => {
              this.$router.push({ name: 'satisfaction-mine' })
            } // TODO
          },
          attrs: { disabled: true }
        },
        auditReject: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Audit,
            handler: this.handleSubmit
          },
          attrs: { disabled: false }
        },
        auditAutoReject: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            ...Button_Audit,
            handler: this.handleSubmit
          },
          attrs: { disabled: false }
        },
        auditPass: {
          comp: InviterPeopleOpera,
          data: this.inviterFormData,
          payload: {
            ...this.inviterPerson
          },
          buttonComp: {
            comp: InviterButtons,
            text: '修改评价人',
            attrs: { plain: true },
            handler: this.handleChangePeople // TODO
          },
          attrs: { disabled: true }
        }
      }
    },
    stateAttrs() {
      return ['auditing', 'auditPass', 'auditReject', 'auditAutoReject', 'auditStart']
    }
  },
  created() {
    this.loadInvitePersonList()
    // if (Number(this.inviterPersonNums)) {
    //   await this.loadInvitePersonList()
    // } else {
    //   this.currentState = this.stateAttrs[4]
    // }
  },
  methods: {
    handleSubmit() {
      const _form = this.$refs['inviterRef'].$refs['inviterForm']
      _form.validate(async valid => {
        if (valid) {
          const _inviteList = this.inviterFormData?.inviterPeople.map(i => {
            return {
              invitedUserId: i.userId,
              invitedUserName: i.userName
            }
          })
          const { code } = await mineInviteApprove({
            auditUserId: this.inviterFormData.approvePeople.userId, // 审核用户id
            auditUserName: this.inviterFormData.approvePeople.userName, // 审核用户姓名
            inviteList: _inviteList //邀请列表
          })
          if (code === '000000') {
            this.$msgSuccess('邀请成功')
            this.currentState = this.stateAttrs[0]
          }
        } else {
          return false
        }
      })
    },
    /**
     * 加载邀请评价人列表 TODO
     */
    async loadInvitePersonList() {
      const { code, data } = await mineInviteQuery()
      if (code === '000000') {
        this.inviterPerson = data ?? {}
        if (Number(this.inviterPerson?.outputs?.length ?? 0)) {
          this.currentState = this.stateAttrs[data.state]
        } else {
          this.currentState = this.stateAttrs[4]
        }
        const _inviterPersons = this.inviterPerson?.outputs?.map(o => {
          return {
            userId: o.userId,
            userName: o.userName
          }
        })
        if (_inviterPersons?.length) {
          this.inviterFormData.inviterPeople = [..._inviterPersons]
        }
        const _approvePeople = this.inviterPerson?.auditUserId
          ? {
              userId: this.inviterPerson?.auditUserId,
              userName: this.inviterPerson?.auditUserName
            }
          : {}
        if (Object.keys(_approvePeople).length) {
          this.inviterFormData.approvePeople = { ..._approvePeople }
        }
      }
    },

    /**
     * 修改评价人
     */
    handleChangePeople() {
      this.currentState = 'auditStart'
    }
  }
}
</script>

<style lang="scss">
.satisfaction-inviter-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &__section {
    border-radius: 5px;
    background: $white;
    margin: 20px 30px;
    padding: 20px 15px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    & > .small-user-card {
      padding-bottom: 20px;
      box-shadow: 0px 1px 0px 0px $linecolor;
      .username {
        font-size: 18px;
        font-weight: bold;
        color: $lightblack;
        line-height: 18px;
      }
      .small-user-card__right {
        flex: none;
      }
    }
    & > .inviter-title {
      font-size: 16px;
      font-weight: bold;
      color: $lightblack;
      line-height: 16px;
      margin: 30px 0;
      display: flex;
      align-items: center;
      &--green {
        color: $green;
      }
      &--black {
        color: $lightblack;
      }
      &--red {
        color: $red;
      }
      p {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
      }
      .icon {
        margin-right: 10px;
        font-size: 19px;
        font-weight: 400;
      }
      .desc {
        margin-left: 15px;
        font-size: 12px;
        font-weight: 400;
        color: $grey;
        line-height: 12px;
      }
    }
    & > .inviter-form {
      flex: 1;
      overflow: auto;
    }
  }
  .inviter-footer {
    text-align: right;
    background: $white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 15px 30px;
    .el-button {
      width: 166px;
    }
  }
}
</style>
