<template>
  <el-form
    ref="inviterForm"
    :model="data"
    :rules="formRules"
    :disabled="disabled"
    label-position="top"
    :validate-on-rule-change="false"
    class="inviter-people-opera"
  >
    <el-form-item label="邀请人员：" prop="inviterPeople">
      <tojoy-select
        ref="inviterPeopleRef"
        v-model="data.inviterPeople"
        :reserveKeyword="false"
        :disabled="false"
        :multiple="true"
        :remote="true"
        :propsAlias="{ value: 'userId', label: 'userName' }"
      />
    </el-form-item>
    <el-form-item label="审批人员：" prop="approvePeople">
      <tojoy-select
        ref="approvePeopleRef"
        v-model="data.approvePeople"
        :reserveKeyword="false"
        :disabled="false"
        :multiple="false"
        :remote="true"
        :isItemObj="true"
      />
    </el-form-item>
  </el-form>
</template>
<script>
import TojoySelect from '@/components/common/tojoy-select/person-selected'
export default {
  name: 'inviter-people-opera',
  components: {
    TojoySelect
  },
  props: {
    /**
     * 是否只读
     * true-读 false-写
     */
    disabled: {
      type: Boolean,
      default: false
    },
    data: {
      require: true,
      type: Object
    },
    payload: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formRules: {
        inviterPeople: [
          { required: true, message: '请选择邀请人', trigger: 'change' },
          { validator: this.validateInviterNum, trigger: 'change' }
        ],
        approvePeople: [
          { required: true, message: '请选择审批人', trigger: 'change' },
          { validator: this.validateApproveNum, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    validateInviterNum(rule, value, callback) {
      if (!value) {
        return callback(new Error('请选择邀请人'))
      }
      setTimeout(() => {
        const { minNum, maxNum } = this.payload
        if (value.length < minNum) {
          callback(new Error(`评价人不得少于${minNum}人`))
        } else if (value.length > maxNum) {
          callback(new Error(`评价人不得超过${maxNum}人`))
        } else {
          callback()
        }
      })
    },
    validateApproveNum(rule, value, callback) {
      setTimeout(() => {
        if (!Object.keys(value).length) {
          callback(new Error(`请选择审核人`))
        } else {
          callback()
        }
      })
    }
  }
}
</script>
<style lang="scss">
.inviter-people-opera {
  .el-form-item {
    margin-bottom: 10px;
    &__label {
      padding-bottom: 0;
    }
    &__error {
      padding-left: 10px;
    }
  }
  .el-select {
    width: 100%;
    &__tags {
      .el-tag {
        margin-right: 10px;
        margin-bottom: 10px;
        background: $white;
        border-radius: 17px;
        padding: 0 17px;
        font-size: 12px;
        font-weight: 400;
        color: $lightblack;
        &--small {
          height: 32px;
          line-height: 30px;
        }
        &:hover {
          border-color: $grey550;
        }
      }
    }
    &__input {
      margin-left: 10px;
    }
    .el-input__inner {
      border: none;
      border-bottom: 1px solid $linecolor;
      padding: 0 10px;
    }
  }
}
</style>
