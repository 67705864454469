const inviterButtons = {
  name: 'inviter-button',

  functional: true,

  props: {
    btnText: {
      require: true,
      type: String
    },
    className: {
      type: String,
      default: 'inviter-button'
    },
    otherAttrs: {
      type: Array,
      default: () => []
    }
  },

  render: (h, { props, data }) => {
    return (
      <el-button {...props.otherAttrs} {...data} class={props.className}>
        {props.btnText}
      </el-button>
    )
  }
}

export default inviterButtons
